<template>
 <div>
   <input type="button"  class="switcher"  :value="locale"  @click="switchLocale()" >
 </div>
</template>
<script>
export default {
  name: 'LocaleSwitchingList',
  date () {
    return {
      locale: 'English'
    }
  },
  created () {
    this.locale = (this.$i18n.locale === 'English' ? 'العربية' : 'English')
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      localStorage.Lang = newVal
      localStorage.locale = (this.$i18n.locale === 'English' ? 'en' : 'ar')
      window.location.reload()
    }
  },
  methods: {
    switchLocale () {
      if (this.$i18n.locale === 'English') {
        this.$i18n.locale = 'العربية'
      } else this.$i18n.locale = 'English'
    }
  }
}
</script>
<style scoped>
.switcher {
  background-color: #4f5d73 !important; /* Green */
  border: none;
  color: white !important;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
}
.switcher:hover{
opacity: 0.8;
}
</style>
