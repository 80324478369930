import i18n from '../i18n'
import $ from 'jquery'
import router from '../router'

const allItems = [
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.dashboard'),
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.orders'),
    to: '/orders',
    icon: 'cil-chart-pie',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.purchaseInvoices'),
    to: '/purchase-invoices',
    icon: 'cil-chart-pie'
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.paymentInvoices'),
    to: '/payments',
    icon: 'cil-chart-pie',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.customers'),
    to: '/customers',
    icon: 'cil-people',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.products'),
    to: '/products',
    icon: 'cil-calculator',
    badge: {
    }

  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.expenses'),
    to: '/expenses',
    icon: 'cil-chart-pie',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.employees'),
    to: '/employees',
    icon: 'cil-people',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.jobs'),
    to: '/jobs',
    icon: 'cil-calculator',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavDropdown',
    name: i18n.t('message.reports'),
    icon: 'cil-list',
    to: '/reports',
    items: [
      // {
      //   _name: 'CSidebarNavItem',
      //   name: i18n.t('message.customerReports'),
      //   to: '/reports/customer-reports'
      // },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.payroll'),
        to: '/reports/payroll'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.ordersReports'),
        to: '/reports/orders-reports'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.expensesReports'),
        to: '/reports/expenses-reports'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.inventoryReports'),
        to: '/reports/inventory-reports'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.salesReport'),
        to: '/reports/sales-report'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.analysisReport'),
        to: '/reports/analysis-reports'
      },
      {
        _name: 'CSidebarNavItem',
        name: i18n.t('message.salesPercentChart'),
        to: '/reports/sales-percent-chart'
      }

    ]
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.offices'),
    to: '/offices',
    icon: 'cil-calculator',
    badge: {
    }

  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.users'),
    to: '/users',
    icon: 'cil-calculator',
    badge: {
    }

  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.role'),
    to: '/roles',
    icon: 'cil-calculator',
    badge: {
    }
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.suppliers'),
    to: '/suppliers',
    icon: 'cil-people'
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.categories'),
    to: '/categories',
    icon: 'cil-calculator'
  },
  {
    _name: 'CSidebarNavItem',
    name: i18n.t('message.export'),
    to: '/export',
    icon: 'cil-calculator'
  }
]

const userSidebar = []
const permissions = []

if (!localStorage.permissions) {
  localStorage.clear()
  router.push({ name: 'Login' })
} else if (localStorage.token && localStorage.isAdmin === '1') {
  allItems.forEach(function (item) {
    userSidebar.push(item)
  })
} else if (localStorage.permissions.length) {
  console.log(localStorage.permissions)
  $.each(localStorage.permissions.split(','), function (key, permission) {
    if (permission.includes('ShowOtherOfficesInventory')) {
      return true
    } else {
      permissions.push(permission.split(':')[1].toLowerCase())
    }
  })
  const permissionsList = [...new Set(permissions)]
  allItems.forEach(function (item) {
    const path = routePath(item)
    if (path[1]) {
      if (path[1] !== 'reports') {
        if (path[1] === 'dashboard' || permissionsList.some((element) => element.includes(path[1].substring(0, path[1].length - 1)))) {
          userSidebar.push(item)
        }
      }
      if (path[1] === 'reports') {
        const newReport = []
        item.items.forEach(function (subItem) {
          console.log(subItem.to.split('/')[2], 'sub')
          if (permissionsList.some((element) => element === subItem.to.split('/')[2])) {
            newReport.push(subItem)
          }
        })
        userSidebar.push({
          _name: 'CSidebarNavDropdown',
          name: i18n.t('message.reports'),
          icon: 'cil-list',
          items: newReport
        })
      }
    }
  })
}

function routePath (item) {
  console.log(item.to)
  if (typeof item.to === 'string') {
    return item.to.split('/')
  } else {
    return 0
  }
}

export default [
  {
    _name: 'CSidebarNav',
    _children: userSidebar
  }
]
